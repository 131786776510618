module.exports = [{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-mdx-virtual-41d0592883/0/cache/gatsby-plugin-mdx-npm-2.3.0-d0d4ce376e-2c8204a170.zip/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-manifest-virtual-2fe4d411a6/0/cache/gatsby-plugin-manifest-npm-2.4.21-1ab408d096-a4589fcf9f.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/assets/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c9a55ddf4d365b32542b2af06d492f69"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
