import React, { useState, useCallback, useContext, useEffect } from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";

import AppContext from "./src/AppContext";
import NavMenu from "./src/NavMenu";
import SubscribeMenu from "./src/SubscribeMenu";
import whyDidYouRender from "@welldone-software/why-did-you-render";

if (process.env.NODE_ENV === "development") {
  whyDidYouRender(React);
}

const GlobalStyling = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    color: ${(props) => props.theme.colors.text};
    font-family: ${(props) => props.theme.fonts.families.regular};
    background: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: ${(props) => (props.overlayVisible ? "hidden" : "auto")}
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${(props) => props.theme.fonts.families.headers};
  }

  button {
    font-family: ${(props) => props.theme.fonts.families.regular};
    outline: none;
    border: none;
  }
  input {
    font-family: ${(props) => props.theme.fonts.families.regular};
    ::placeholder {
      font-family: ${(props) => props.theme.fonts.families.regular};
    }
  }

  #gatsby-focus-wrapper {
    height: 100%;
  }
`;

const theme = {
  colors: {
    primary: "#F5B275",
    secondary: "#A7B939",
    accents1: "#FFCECB",
    accents2: "#355E02",
    backgrounds: "#E0E0E0",
    text: "#282828",
  },
  fonts: {
    families: {
      regular: "'Open Sans', sans-serif",
      headers: "'Merriweather', serif",
    },
    weights: {
      regular: 400,
      bold: 700,
    },
  },
};

const StyledContent = styled.main`
  height: 100vh;
  width: 100vw;
`;

const Content = ({ children }) => {
  const { closeNav } = useContext(AppContext);
  const pathname = "";
  useEffect(() => {
    closeNav();
  }, [pathname, closeNav]);
  return <StyledContent>{children}</StyledContent>;
};

const useOverlayMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const openMenu = useCallback(() => setShowMenu(true), []);
  const closeMenu = useCallback(() => setShowMenu(false), []);
  const toggleMenu = useCallback(
    () => setShowMenu((prevShowMenu) => !prevShowMenu),
    []
  );
  return { shouldShowMenu: showMenu, openMenu, closeMenu, toggleMenu };
};

const RootElementWrapper = (props) => {
  const {
    shouldShowMenu: showNavMenu,
    closeMenu: closeNav,
    toggleMenu: toggleNav,
  } = useOverlayMenu();
  const {
    shouldShowMenu: showSubscribeMenu,
    openMenu: openSubscribeMenu,
    closeMenu: closeSubscribeMenu,
  } = useOverlayMenu();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyling overlayVisible={showNavMenu || showSubscribeMenu} />
      <AppContext.Provider
        value={{ toggleNav, closeNav, openSubscribeMenu, closeSubscribeMenu }}
      >
        <Content>
          <NavMenu visible={showNavMenu} />
          <SubscribeMenu visible={showSubscribeMenu} />
          {props.element}
        </Content>
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default RootElementWrapper;
