import React, { useContext } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import FullPageOverlay from "./FullPageOverlay";
import AppContext from "./AppContext";
import routes from "./routes";

const Links = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  a {
    font-size: 2.25em;
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
    &:visited {
      color: ${(props) => props.theme.colors.text};
    }
    &:hover {
      color: ${(props) => props.theme.colors.accents2};
    }
  }
`;

type NavMenuProps = {
  visible: boolean;
};

const NavMenu: React.FC<NavMenuProps> = ({ visible }) => {
  const { closeNav } = useContext(AppContext);
  return (
    <FullPageOverlay visible={visible} onClose={closeNav}>
      <Links>
        {routes.map((route) => (
          <Link key={route.path} to={route.path} onClick={closeNav}>
            {route.name}
          </Link>
        ))}
      </Links>
    </FullPageOverlay>
  );
};

export default NavMenu;
