import React, { useContext } from "react";
import styled from "styled-components";
import FullPageOverlay from "./FullPageOverlay";
import AppContext from "./AppContext";

const SubscribeMenuContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    margin: 0;
  }
`;

type SubscribeMenuProps = {
  visible: boolean;
};
const SubscribeMenu: React.FC<SubscribeMenuProps> = ({ visible }) => {
  const { closeSubscribeMenu } = useContext(AppContext);
  return (
    <FullPageOverlay visible={visible} onClose={closeSubscribeMenu}>
      <SubscribeMenuContent>
        <h1>Check back soon for subscription links</h1>
      </SubscribeMenuContent>
    </FullPageOverlay>
  );
};

export default SubscribeMenu;
