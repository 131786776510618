import { createContext } from "react";

const AppContext = createContext({
  toggleNav: () => {},
  closeNav: () => {},
  openSubscribeMenu: () => {},
  closeSubscribeMenu: () => {},
});

export default AppContext;
