const routes = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About",
    path: "/about",
  },
  {
    name: "Episodes",
    path: "/episodes",
  },
  {
    name: "Recipes",
    path: "/recipes",
  },
];

export default routes;
