import React from "react";
import styled from "styled-components";
import CloseIcon from "./assets/svgs/close_icon.svg";

type StyledFullPageOverlayProps = {
  visible: boolean;
};

const StyledFullPageOverlay = styled.nav<StyledFullPageOverlayProps>`
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 80vw;
  background: ${(props) => props.theme.colors.backgrounds};
  opacity: 0.95;
  left: ${(props) => (props.visible ? "0" : "-80vw")};
  transition: left 0.2s ease-in-out;
`;

const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  svg {
    height: 25px;
    width: auto;
    cursor: pointer;
    path {
      stroke: ${(props) => props.theme.colors.text};
    }
  }
`;

const OverlayContent = styled.div`
  position: absolute;
  top: calc(25px + 2em);
  width: 100%;
  height: calc(100% - 25px - 2em);
`;

type FullPageOverlayProps = {
  visible: boolean;
  onClose: () => void;
};

const FullPageOverlay: React.FC<FullPageOverlayProps> = ({
  children,
  onClose,
  visible,
}) => {
  return (
    <StyledFullPageOverlay visible={visible}>
      <CloseIconContainer onClick={onClose}>
        <CloseIcon />
      </CloseIconContainer>
      <OverlayContent>{children}</OverlayContent>
    </StyledFullPageOverlay>
  );
};

export default FullPageOverlay;
